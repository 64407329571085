import React from "react";
import Loading from "../components/misc/loading/Loading";
import GeneratePdf from "../components/generatePdf/GeneratePdf";
import { PDFViewer } from "@react-pdf/renderer";
import styled from "styled-components";
import axios from "axios";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { osPdfGenerate } from "../server/routes/os";

const Pdf = () => {
  let osId = window.location.href.split("/");
  osId = parseInt(osId[osId.length - 1]);

  const tk = useSelector((state) => state.isLogged.tk);
  const config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${tk}`,
    },
  };

  const fetchOsPdf = () => {
    return axios.get(`${osPdfGenerate}/${osId}`, config);
  };

  const osPdfQuery = useQuery(["osPdf", osId], fetchOsPdf);

  osPdfQuery.isLoading && <Loading />;

  return (
    <PdfWrapper>
      {osPdfQuery.isFetched && (
        <PDFViewer>
          <GeneratePdf recivedData={osPdfQuery.data?.data} />
        </PDFViewer>
      )}
    </PdfWrapper>
  );
};

const PdfWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  iframe {
    width: 100%;
    height: 100%;
  }
`;

export default Pdf;
