const procSelectReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_SELECTED_PROC":
      return action.payload.id;
    default:
      return state;
  }
};

export default procSelectReducer;
