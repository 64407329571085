import styled, { css } from "styled-components";

function createLoadingTimer() {
  let timers = [
    50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750,
    800, 850, 900, 950, 1000, 1050, 1100, 1150,
  ];

  let styles = "";

  for (let i = 0; i < timers.length; i++) {
    styles += `
      span{
          &:nth-of-type(${i}){
            animation-delay: ${timers[i]}ms !important;
                }
            }


        `;
  }
  return css`
    ${styles}
  `;
}

export const Thing = styled.h2`
  ${createLoadingTimer()};
`;
