const burguerReducer = (state = false, action) => {
  switch (action.type) {
    case "BURGUER_ON":
      return (state = true);
    case "BURGUER_OFF":
      return (state = false);
    case "CHANGE_BURGUER":
      return !state;
    default:
      return state;
  }
};

export default burguerReducer;
