const wallet = (state = "", action) => {
  switch (action.type) {
    case "SET_WALLET":
      return action.payload.wallet;
    default:
      return state;
  }
};

export default wallet;
