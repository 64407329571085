export const getIcons = (
  iconsNav,
  iconsNavQuantity,
  iconsInner,
  iconsInnerQuantity
) => {
  return {
    type: "GET_ICONS",
    payload: {
      getIconsNav: iconsNav,
      getIconsNavQuantity: iconsNavQuantity,
      getIconsInner: iconsInner,
      getIconsInnerQuantity: iconsInnerQuantity,
    },
  };
};

export const clearIcons = () => {
  return {
    type: "CLEAR_ICONS",
  };
};
