const init = {
  idPecas: [],
};

const pecasToChangePhaseReducer = (state = init, action) => {
  switch (action.type) {
    case "CHANGE_PECAS_TO_CHANGE_PHASE":
      // state = action.payload;
      state.idPecas.push(action.payload.idPecas);
      return state;
    case "CLEAR_CHANGE_PECAS_TO_CHANGE_PHASE":
      return init;
    default:
      return { ...state };
  }
};

export default pecasToChangePhaseReducer;
