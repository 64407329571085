const initState = {
  id: "i1",
};

const selectedSideNavReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_SELECTEDSIDENAV":
      return {
        id: action.payload.id,
      };
    case "CLEAR_SELECTEDSIDENAV":
      return {
        id: action.payload.id,
      };
    default:
      return { ...state };
  }
};

export default selectedSideNavReducer;
