import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import osAbi from "../../abi/Neuron_abi.json";
import { set_contract } from "../../actions/blockchain/contract";
import { set_wallet } from "../../actions/blockchain/wallet";
import { clearIcons } from "../../actions/icons";
import { sign_off } from "../../actions/login/isLogged";
import { clearSelectedSideNav } from "../../actions/selectedSideNav";

const ConnectToWallet = () => {
  const dispatch = useDispatch();
  const [walletAddress, setWalletAddress] = useState("");
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [contract, setContract] = useState(null);
  const [seconds, setSeconds] = useState(10);
  const contractAdress = "0x829e1a8B24C43794Fd9F3A96b8aE7894d297A7eB";

  const connectWallet = async () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          accountChangedHandler(result[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const accountChangedHandler = (newAccount) => {
    setWalletAddress(newAccount);
    updateEthers(newAccount);
  };

  const updateEthers = (newAccount) => {
    const providerTemp = new ethers.providers.Web3Provider(window.ethereum);
    setProvider(providerTemp);

    let signerTemp = providerTemp.getSigner();
    setSigner(signerTemp);

    let contractTemp = new ethers.Contract(
      contractAdress,
      osAbi.abi,
      signerTemp
    );
    setContract(contractTemp);
    changeContractAndWallet(newAccount, contractTemp);
  };
  //end Ethers
  const changeContractAndWallet = (acc, cont) => {
    dispatch(set_contract(cont));
    dispatch(set_wallet(acc));
  };

  useEffect(() => {
    if (window.ethereum) {
      connectWallet();
    }
  }, []);

  if (window.ethereum !== undefined) {
    window.ethereum.on("accountsChanged", function (accounts) {
      dispatch(set_wallet(""));
      setWalletAddress("");
      connectWallet();
    });
  }

  if (window.ethereum === undefined) {
    setTimeout(() => {
      setSeconds(seconds - 1);
    }, 1000);
  }

  useEffect(() => {
    if (seconds <= 0) {
      dispatch(sign_off());
      dispatch(clearIcons());
      dispatch(clearSelectedSideNav());
      localStorage.setItem("logged", "false");
      localStorage.setItem("function", "");
      localStorage.setItem("tk", "");
      localStorage.setItem("industria", "");
      localStorage.setItem("innerNav", "c1");
      window.dispatchEvent(new Event("changeLogin"));
    }
    return () => {
      clearTimeout();
    };
  }, [seconds]);

  return (
    <>
      {(window.ethereum === undefined ||
        window.ethereum.isMetaMask === false) && (
        <Wallet>
          <img src="../../img/metamask_fox.png" alt="" />
          <h2>Conecte-se a uma carteira MetaMask!</h2>
          <p>Você será desconectado em: {seconds}</p>
        </Wallet>
      )}
    </>
  );
};

const Wallet = styled.div`
  position: absolute;
  z-index: 999;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 20%;
  }
  h2 {
    text-align: center;
    font-size: 5rem;
    color: #f6851b;
    font-weight: 900;
    text-shadow: 1px 1px 5px #cd6116;
  }
  p {
    text-align: center;
    font-size: 1.2rem;
    color: #f6851b;
    font-weight: 900;
    text-shadow: 1px 1px 5px #cd6116;
  }
`;

export default ConnectToWallet;
