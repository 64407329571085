import counterReducer from "./counter";
import loggedReducer from "./isLogged";
import burguerReducer from "./burguer";
import darkReducer from "./darkMode";
import languageReducer from "./language";
import iconsReducer from "./icons";
import selectedSideNavReducer from "./selectedSideNav";
import selectedInnerNavReducer from "./selectedInnerNav";
import editableInfoAdminReducer from "./editableInfoAdmin";
import procSelectReducer from "./procedimentoSelect";
import profissionalSelect from "./profissionalSelect";
import selectedDateInterval from "./selectedDateInterval";
import equipamentoSelect from "./selectedEquipamento";
import wallet from "./blockchain/wallet";
import contract from "./blockchain/contract";
import dropZoneReducer from "./dropZone";
import attatchmentsToDeleteReducer from "./attatchmentsToDelete";
import pecasToChangePhaseReducer from "./pecasIdToChangePhase";
import { combineReducers } from "redux";

const allReducers = combineReducers({
  language: languageReducer,
  darkMode: darkReducer,
  burguer: burguerReducer,
  counter: counterReducer,
  isLogged: loggedReducer,
  getIcons: iconsReducer,
  getSelectedSideNav: selectedSideNavReducer,
  getSelectedInnerNav: selectedInnerNavReducer,
  editableInfoAdmin: editableInfoAdminReducer,
  procedimentoSelect: procSelectReducer,
  profissionalSelect: profissionalSelect,
  dateIntervalSelect: selectedDateInterval,
  equipamentoSelect: equipamentoSelect,
  wallet: wallet,
  contract: contract,
  dropZone: dropZoneReducer,
  attatchmentsToDelete: attatchmentsToDeleteReducer,
  pecasToChangePhase: pecasToChangePhaseReducer,
});

export default allReducers;
