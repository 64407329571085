export const getSelectedSideNav = (id) => {
  return {
    type: "GET_SELECTEDSIDENAV",
    payload: {
      id: id,
    },
  };
};

export const clearSelectedSideNav = () => {
  return {
    type: "CLEAR_SELECTEDSIDENAV",
    payload: {
      id: "i1",
    },
  };
};
