const initState = {
  logged: false,
  function: "",
};

const loggedReducer = (state = initState, action) => {
  switch (action.type) {
    case "SIGN_IN":
      return {
        logged: true,
        function: action.payload.function,
        tk: action.payload.tk,
        industria: action.payload.industria,
      };
    case "SIGN_OFF":
      return {
        logged: false,
        function: "",
        tk: "",
        industria: "",
      };
    default:
      return state;
  }
};

export default loggedReducer;
