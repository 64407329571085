const init = {
  attatchments: [],
};

const attatchmentsToDeleteReducer = (state = init, action) => {
  switch (action.type) {
    case "ADD_ATTATCHMENTS":
      state.attatchments.push(action.payload.attatchment);
      return state;
    case "REMOVE_ATTATCHMENTS":
      state.attatchments = state.attatchments.filter(
        (el) => el !== action.payload.attatchment
      );
      return state;
    case "CLEAR_ATTATCHMENTS":
      state.attatchments = [];
      return state;
    default:
      return { ...state };
  }
};

export default attatchmentsToDeleteReducer;
