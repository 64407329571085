import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { createStore } from "redux";
import allReducers from "./reducers";
import { Provider } from "react-redux";
import Loading from "./components/misc/loading/Loading";
import { ErrorBoundary } from "react-error-boundary";
import axios from "axios";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { createErrorLog } from "./server/routes/errorLog";
import icro from "../src/img/icrodigital.png";

const store = createStore(
  allReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const options = {
  position: positions.TOP_RIGHT,
  offset: "5px",
  transition: transitions.SCALE,
};

const tk = localStorage.getItem("tk");

const config = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    Authorization: `Bearer ${tk}`,
  },
};

const logError = (error, info) => {
  let date = `${
    new Date().toISOString().split("T")[0]
  } ${new Date().toLocaleTimeString()}`;
  axios.post(
    createErrorLog,
    {
      stack: info.componentStack.toString(),
      message: error.toString(),
      date: date,
    },
    config
  );
};

function redirectPainel() {
  window.location.href = "https://painel-front-78eo.vercel.app/painel";
}

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div
      role="alert"
      className="flex w-screen h-screen justify-center items-center flex-col"
    >
      <img className="p-4" src={icro} width={450} height={350} alt="Icro Digital" />
      <p className="font-bold text-3xl">
        Algo deu errado! Por favor, tente novamente:
      </p>
      <button onClick={redirectPainel}>
        <p className="font-bold text-3xl">
          {" "}
          Refaça seu acesso pelo Painel Administrativo novamente.
        </p>
        <p className="font-bold text-3xl">
          {" "}
          Se o erro persistir, entre em contato com o suporte.
        </p>
      </button>
      <pre className="text-red-600 ">Erro: {error.message}</pre>
    </div>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <AlertProvider template={AlertTemplate} {...options}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
        <Suspense fallback={<Loading />}>
          <BrowserRouter>
            <Provider store={store}>
              <App />
            </Provider>
          </BrowserRouter>
        </Suspense>
      </ErrorBoundary>
    </AlertProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
