export const sign_in = (func, tk = "", industria) => {
  return {
    type: "SIGN_IN",
    payload: {
      function: func,
      tk: tk,
      industria: industria,
    },
  };
};

export const sign_off = () => {
  return {
    type: "SIGN_OFF",
    payload: {
      function: "",
      tk: "",
      industria: "",
    },
  };
};
