// languageActionsAndReducer.js

// Tipos de ação
const LANG_PT = "LANG_PT";
const LANG_ES = "LANG_ES";
const LANG_EN = "LANG_EN";

// Ações
export const setLanguagePT = () => ({ type: LANG_PT });
export const setLanguageES = () => ({ type: LANG_ES });
export const setLanguageEN = () => ({ type: LANG_EN });

// Reducer
const languageReducer = (state = "pt", action) => {
  switch (action.type) {
    case LANG_PT:
      return "pt";
    case LANG_ES:
      return "es";
    case LANG_EN:
      return "en";
    default:
      return state;
  }
};

export default languageReducer;
