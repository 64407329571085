import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { Suspense, useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "styled-components";
import { sign_in } from "./actions/login/isLogged";
import ConnectToWallet from "./components/blockchain/ConnectToWallet";
import GlobalStyles from "./components/GlobalStyles";
import Loading from "./components/misc/loading/Loading";
import TokenRefresh from "./components/misc/tokenRefresh/TokenRefresh";
import "./css/Global.css";
import Pdf from "./pages/Pdf";
import { login } from "./server/routes/player";
import { darkTheme, lightTheme } from "./theme";

const Login = React.lazy(() => import("./pages/Login"));
const Preditiva = React.lazy(() => import("./pages/Preditiva"));
const Manutencao = React.lazy(() => import("./pages/Manutencao"));
const Producao = React.lazy(() => import("./pages/Producao"));
const Fornecedor = React.lazy(() => import("./pages/Fornecedor"));
const Admin = React.lazy(() => import("./pages/Admin"));
const Almoxarifado = React.lazy(() => import("./pages/Almoxarifado"));

const App = () => {
  const dispatch = useDispatch();
  const [isLogged, setIsLogged] = useState(false);
  const walletAddressRequest = useSelector((state) => state.wallet);
  let walletAddress = "1";
  const [who, setWho] = useState("");
  const [tk, setTk] = useState("");
  const [industria, setIndustria] = useState("");

  const theme = useSelector((state) => state.darkMode);

  const queryClient = new QueryClient();
  if (walletAddressRequest !== "") {
    walletAddress = walletAddressRequest;
  }

  const loginOptions = [
    "administrador",
    "preditiva",
    "manutencao",
    "producao",
    "fornecedor",
    "almoxarifado",
  ];

  const validToken = async () => {
    const url = window.location.href;
    const token = url.split("/").pop();
    if (!token) return false;
    try {
      const decodedToken = jwtDecode(token);
      
      localStorage.setItem("newGrupos", decodedToken.tokenNeuron);
      localStorage.setItem("newIndustry", decodedToken.industriaSelected);
      localStorage.setItem("newUsername", decodedToken.user_name);
      localStorage.setItem("roleByPainel", decodedToken.rolePlant);
      return true;
    } catch (error) {
      return false;
    }
  };

  const gruposToken = localStorage.getItem("newGrupos");

  const gruposNeuron = [
    {
      id: 1,
      nome: "Administrador",
      value: "administrador",
    },
    {
      id: 2,
      nome: "Preditiva",
      value: "preditiva",
    },
    {
      id: 3,
      nome: "Produção",
      value: "producao",
    },
    {
      id: 4,
      nome: "Fornecedor",
      value: "fornecedor",
    },
    {
      id: 5,
      nome: "Manutenção",
      value: "manutencao",
    },
    {
      id: 6,
      nome: "Almoxarifado",
      value: "almoxarifado",
    },
  ];

  const gruposCorrespondentes = gruposNeuron
    .filter((grupo) => gruposToken?.includes(grupo.id))
    .map((grupo) => ({
      id: grupo.id,
      name: grupo.nome,
      value: grupo.value,
    }));

  const saveStorage = (res) => {
    localStorage.setItem("refreshTokenTime", new Date().getTime());
    localStorage.setItem("refreshLongerTokenTime", new Date().getTime());
    localStorage.setItem("logged", true);
    localStorage.setItem("function", gruposCorrespondentes[0]?.value);
    localStorage.setItem(
      "authGrupos",
      res.player.grupos.map((grupo) => grupo.id)
    );
    localStorage.setItem("name", res.player.nome);
    localStorage.setItem("tk", res.tk);
    localStorage.setItem("refreshToken", res.refreshTk);
    localStorage.setItem("idPlayer", res.player.id);
    localStorage.setItem("username", res.player.usuario);
    
    window.dispatchEvent(new Event("changeLogin"));
    dispatch(sign_in(res.player.grupo, res.tk, res.player.idsIndustrias));
    window.location.reload();
  };

  const forceLogin = async () => {
    const fetchLogin = async (username, password, setLocalStorage) => {
      return axios
        .post(login, {
          usuario: username,
          senha: password,
        })
        .then((response) => {
          !!setLocalStorage && setLocalStorage();
          saveStorage(response.data);
        })
        .catch((error) => {
          localStorage.setItem("logged", false);
          window.dispatchEvent(new Event("changeLogin"));
          alert(
            "Falha de API! \n Verifique o console ou entre em contato com o suporte."
          );
          console.error(error);
        });
    };

    const url = window.location.href;
    const token = url.split("/").pop();
    const decodedToken = jwtDecode(token);
    const industriaSelected = decodedToken.industriaSelected;
    const supplier_plant_id = decodedToken.supplier_plant_id;
    const roleNeuron = decodedToken.tokenNeuron;
    const userLogin = decodedToken.user_name;
    const passwordLogin = decodedToken.password;

    const setLocalStorage = () => {
      localStorage.setItem("grupos", roleNeuron);
      localStorage.setItem("supplier_plant_id", supplier_plant_id);
      localStorage.setItem("industria", industriaSelected);
    };

    await fetchLogin(userLogin, passwordLogin, setLocalStorage());
  };

  const verifyLocalStorage = () => {
    let loginOption = localStorage.getItem("function");
    if (!loginOptions.includes(loginOption)) {
      localStorage.setItem("logged", false);
      localStorage.setItem("function", "");
      localStorage.setItem("tk", "");
    }
    if (
      localStorage.getItem("logged") === null ||
      localStorage.getItem("logged") === "false"
    ) {
      validToken().then((tokenValid) => {
        if (tokenValid) return forceLogin();

        alert("Token inválido!");
        window.close();
      });
    } else if (localStorage.getItem("logged") === "true") {
      let isSessionValid = false;
      validToken()
        .then((response) => {
          const usernameInStorage = localStorage.getItem("username");
          const newUsername = localStorage.getItem("newUsername");

          if (
            !newUsername ||
            !usernameInStorage ||
            usernameInStorage != newUsername
          )
            return;

          const newIndustry = localStorage.getItem("newIndustry");
          const industriaInStorage = localStorage.getItem("industria");

          if (
            !newIndustry ||
            !industriaInStorage ||
            newIndustry != industriaInStorage
          ) {
            const retryLogin = () => {
              localStorage.setItem("logged", false);
            };

            retryLogin.then(() => {
              verifyLocalStorage();
            });
            return;
          }

          isSessionValid = true;
          return;
        })
        .then(() => {
          if (!isSessionValid) {
            localStorage.clear();
            alert("Sessão inválida!");
            window.close();
          }

          setIsLogged(true);
          setWho(localStorage.getItem("function"));
          setTk(localStorage.getItem("tk"));
          setIndustria(localStorage.getItem("industria"));
          dispatch(
            sign_in(
              localStorage.getItem("function"),
              localStorage.getItem("tk"),
              localStorage.getItem("industria")
            )
          );
        });
    }
  };

  window.addEventListener("changeLogin", () => {
    verifyLocalStorage();
  });

  useEffect(() => {
    verifyLocalStorage();
  }, []);

  const verifyNameUrl = () => {
    const nameUrl = window.location.href.split("/");
    if (nameUrl.includes("pdf")) {
      return true;
    } else {
      return false;
    }
  };

  const isDarkMode = !theme ? "light" : "dark";

  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer
        position="top-right"
        autoClose={3500}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={isDarkMode}
      />
      {isLogged && <TokenRefresh />}
      <ThemeProvider theme={!theme ? lightTheme : darkTheme}>
        <GlobalStyles />
        <Suspense fallback={<Loading />}>
          {process.env.REACT_APP_USE_BLOCKCHAIN === "true" && isLogged && (
            <ConnectToWallet />
          )}
          <Routes>
            {!isLogged && <Route path="/:token" element={<Login />} />}
            {isLogged && walletAddress !== "" && (
              <>
                {who === "preditiva" && (
                  <Route path="/preditiva" element={<Preditiva />} />
                )}
                {who === "administrador" && walletAddress !== "" && (
                  <Route path="/administrador" element={<Admin />} />
                )}
                {who === "manutencao" && (
                  <Route path="/manutencao" element={<Manutencao />} />
                )}
                {who === "fornecedor" && (
                  <Route path="/fornecedor" element={<Fornecedor />} />
                )}

                {who === "producao" && (
                  <Route path="/producao" element={<Producao />} />
                )}
                {who === "almoxarifado" && (
                  <Route path="/almoxarifado" element={<Almoxarifado />} />
                )}
                <Route path="/pdf/*" element={<Pdf />} />
              </>
            )}
            {verifyNameUrl() !== true && (
              <Route
                path="*"
                element={<Navigate to={isLogged ? `/${who}` : "/:token"} />}
              />
            )}{" "}
          </Routes>
        </Suspense>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} position="top-right" />
    </QueryClientProvider>
  );
};

export default App;
