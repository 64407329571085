const darkReducer = (state = false, action) => {
  switch (action.type) {
    case "DARK_ON":
      return (state = true);
    case "DARK_OFF":
      return (state = false);
    case "DARK_CHANGE":
      return !state;
    default:
      return state;
  }
};

export default darkReducer;
