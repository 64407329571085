const editableInfoAdminReducer = (state = "edit", action) => {
  switch (action.type) {
    case "ADMIN_ADD":
      return (state = "add");
    case "ADMIN_EDIT":
      return (state = "edit");
    default:
      return state;
  }
};

export default editableInfoAdminReducer;
