const dropZoneReducer = (state = [], action) => {
  switch (action.type) {
    case "UPDATE_FILES":
      return action.payload.files;
    default:
      return state;
  }
};

export default dropZoneReducer;
