import { createGlobalStyle } from "styled-components";
import "../css/anim.scss";

const GlobalStyles = createGlobalStyle`

    @font-face {
        font-family: 'Futura';
        src: url('/fonts/Futura\ Bk\ BT\ Book.ttf') format('truetype'),
             url('/fonts/Futura\ Bk\ BT\ Book\ Italic.ttf') format('truetype'),
             url('/fonts/Futura\ Md\ BT\ Bold.ttf') format('truetype'),
             url('/fonts/Futura\ Md\ BT\ Bold\ Italic.ttf') format('truetype'),
             url('/fonts/Futura\ Md\ BT\ Medium.ttf') format('truetype'),
             url('/fonts/Futura\ Md\ BT\ Medium\ Italic.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Riporno';
        src: url('/fonts/Riporno.otf') format('opentype');
        font-weight: normal;
        font-style: normal;
    }

    :root{
        --clr__icro: #efefef;
        --clr__icro-dark: #bcbcbc;
        --clr__primary: #fa4517;
        --clr__secondary: #b32401;
        --clr__third: #C0D8FD;
        --clr__light__gray:#ccc;
        --clr__medium__gray:#9E989B;
        --clr__dark__gray: #212529;
        --clr__white:#fff;
        --clr__black: #0c0c0c;
        --clr__blue: #1f1547;
        --clr__red: #ee1111;
        --clr__red-hover: #881111;

        --shadow-box: 0px 0px 5px ${({ theme }) => theme.shadow};

        --custom-bg: #1f1547;
        --custom-text: #ffffff;
    }
    html{
        overflow: hidden;

        @media screen and (max-height: 500px) {
            overflow-y: auto !important;
        }
    }
    *{
        margin:0;
        padding:0;
        box-sizing:border-box;
    }
    body{
        background: ${({ theme }) => theme.body};
        color: ${({ theme }) => theme.text};
        font-family: 'Futura', sans-serif;
    }

    html * {
        font-family: 'Futura', sans-serif;
    }

    body,p,a,span,h1,h2,h3,h4, textarea{
        font-family: 'Futura', sans-serif;
        width: 100%;
    }
    h1{
    }
    h2{
        font-size: clamp(1rem, 5vw, 3rem);
        font-weight:lighter;
        color: ${({ theme }) => theme.text};
    }
    h3{
        font-size: clamp(1rem,2vw ,1.5rem);
    }
    p{
    }
    a{
        text-decoration:none;
    }
    input,textarea{
        border:1px solid var(--clr__medium__gray);
    }

    span > img.smallSizeImg{
        top:25%;
        width: 20px;
        @media (max-width: 850px) {
            width: 18px;
        }
    }

    .rotateArrow{
        margin-top:0.8rem;
        transform: rotateX(180deg);
    }

    .rotate{
        transform: rotateX(180deg);
    }

    .darkModeSelect{
        color: white;
        background: black;
    }

    .lightModeSelect{
        color: black;
        background: white;
    }

    .hide{
        display: none; 
    }

    .smallSideBar{
        width: 4rem !important;
        nav{
            align-items: center;
            div{
                div{
                    justify-content: center; /* last icon center */
                    margin-right:0;
                }
            }
            nav{
                max-height:350px;
                overflow-y:auto;
                @media(min-height: 850px){
                    max-height:100%;
                }
            }
        }
        div{
            width:100% ;
        }
    }

    .reverse_anim {
      animation: show_rev 250ms ease !important;
    }

    @keyframes show_rev {
      0% {
        bottom: 0;
        opacity: 1;
      }
      100% {
        bottom: -10rem;
        opacity: 0;
      }
    }
    @keyframes show {
      0% {
        bottom: -10rem;
        opacity: 0.3;
      }
      100% {
        bottom: 0;
        opacity: 1;
      }
    }

    .react-date-picker__wrapper{
            padding:0.2rem;
    }
    .react-daterange-picker__wrapper {
        input, svg > *{
        color:${({ theme }) => theme.text};
        stroke:${({ theme }) => theme.text};
        }
    }

    ::-webkit-scrollbar {
    width: 10px;
    height:10px ;
    }
    ::-webkit-scrollbar-track {
    border-radius: 8px;
    background: ${({ theme }) => theme.scrollBarBackground};
    }

    ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${({ theme }) => theme.scrollBar};
    &:hover {
      background-color: ${({ theme }) => theme.scrollBarHover};
    }
    }

    /* Custom styles for steps */
    .steps .step:before {

    }

    .steps .step-secondary + .step-secondary:before,
    .steps .step-secondary:after {
        --tw-bg-opacity: 1;
        background-color: var(--clr__primary);
        --tw-text-opacity: 1;
        color: var(--clr__white);
    }


    .steps .step:after {
      background-color: var(--custom-bg);
      color: var(--custom-text);
    }

    .steps .step:first-child:before {
      content: none;
    }

    .steps .step[data-content]:after {
      content: attr(data-content);
    }

    .steps-horizontal .step {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-template-rows: repeat(2, minmax(0, 1fr));
      place-items: center;
      text-align: center;
    }

    .steps-vertical .step {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: repeat(1, minmax(0, 1fr));
    }

    .steps-horizontal .step {
      grid-template-rows: 40px 1fr;
      grid-template-columns: auto;
      min-width: 4rem/* 64px */;
    }

    .steps-horizontal .step:before {
      height: 0.5rem/* 8px */;
      width: 100%;
      --tw-translate-x: 0px;
      --tw-translate-y: 0px;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
      content: "";
      margin-inline-start: -100%;
    }

    :is([dir="rtl"] .steps-horizontal .step):before {
      --tw-translate-x: 0px;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }

    .steps-vertical .step {
      gap: 0.5rem/* 8px */;
      grid-template-columns: 40px 1fr;
      grid-template-rows: auto;
      min-height: 4rem/* 64px */;
      justify-items: start;
    }

    .steps-vertical .step:before {
      height: 100%;
      width: 0.5rem/* 8px */;
      --tw-translate-x: -50%;
      --tw-translate-y: -50%;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
      margin-inline-start: 50%;
    }

    :is([dir="rtl"] .steps-vertical .step):before {
      --tw-translate-x: 50%;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
`;

export default GlobalStyles;
