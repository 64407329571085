import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

const loginPageES = require("./locales/es/loginPage.json");
const loginPagePT = require("./locales/pt/loginPage.json");
const loginPageEN = require("./locales/en/loginPage.json");
const loadingES = require("./locales/es/loading.json");
const loadingPT = require("./locales/pt/loading.json");
const loadingEN = require("./locales/en/loading.json");
const navES = require("./locales/es/navbar.json");
const navPT = require("./locales/pt/navbar.json");
const navEN = require("./locales/en/navbar.json");
const centerES = require("./locales/es/centerContent.json");
const centerPT = require("./locales/pt/centerContent.json");
const centerEN = require("./locales/en/centerContent.json");
const preditivaES = require("./locales/es/preditivaPage.json");
const preditivaPT = require("./locales/pt/preditivaPage.json");
const preditivaEN = require("./locales/en/preditivaPage.json");
const producaoES = require("./locales/es/producaoPage.json");
const producaoPT = require("./locales/pt/producaoPage.json");
const producaoEN = require("./locales/en/producaoPage.json");
const toastES = require("./locales/es/toast.json");
const toastPT = require("./locales/pt/toast.json");
const toastEN = require("./locales/en/toast.json");
const almoxarifadoES = require("./locales/es/almoxarifadoPage.json");
const almoxarifadoPT = require("./locales/pt/almoxarifadoPage.json");
const almoxarifadoEN = require("./locales/en/almoxarifadoPage.json");
const manutencaoES = require("./locales/es/manutencaoPage.json");
const manutecaoPT = require("./locales/pt/manutencaoPage.json");
const manutencaoEN = require("./locales/en/manutencaoPage.json");
const graphAdminES = require("./locales/es/graphAdmin.json");
const graphAdminPT = require("./locales/pt/graphAdmin.json");
const graphAdminEN = require("./locales/en/graphAdmin.json");

// Initialize LanguageDetector with local storage as a priority
const languageDetector = new LanguageDetector(null, {
  order: ['localStorage', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain'],
  caches: ['localStorage'],
  lookupLocalStorage: 'i18nextLng', // key in local storage
});

i18n
  .use(languageDetector) // add the detector plugin
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      pt: {
        translations: {
          ...loginPagePT,
          ...navPT,
          ...centerPT,
          ...preditivaPT,
          ...loadingPT,
          ...graphAdminPT,
          ...manutecaoPT,
          ...producaoPT,
          ...almoxarifadoPT,
          ...toastPT,
        },
      },
      es: {
        translations: {
          ...loginPageES,
          ...navES,
          ...centerES,
          ...preditivaES,
          ...loadingES,
          ...graphAdminES,
          ...manutencaoES,
          ...producaoES,
          ...almoxarifadoES,
          ...toastES,
        },
      },
      en: {
        translations: {
          ...loginPageEN,
          ...navEN,
          ...centerEN,
          ...preditivaEN,
          ...loadingEN,
          ...graphAdminEN,
          ...manutencaoEN,
          ...producaoEN,
          ...almoxarifadoEN,
          ...toastEN,
        },
      },
    },
    fallbackLng: "pt",
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage'],
      lookupLocalStorage: 'i18nextLng', // This is the key that will be used to store the language in localStorage
    },
  });

export default i18n;
