const initState = {
  start: [],
  end: [],
};

const selectedDateInterval = (state = initState, action) => {
  switch (action.type) {
    case "GET_DATE":
      return {
        start: action.payload.getStart,
        end: action.payload.getEnd,
      };
    default:
      return { ...state };
  }
};

export default selectedDateInterval;
