const initState = {
  iconsNav: {},
  iconsNavQuantity: 0,
  iconsInner: {},
  iconsInnerQuantity: 0,
};

const iconsReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_ICONS":
      return {
        iconsNav: action.payload.getIconsNav,
        iconsNavQuantity: action.payload.getIconsNavQuantity,
        iconsInner: action.payload.getIconsInner,
        iconsInnerQuantity: action.payload.getIconsInnerQuantity,
      };
    case "CLEAR_ICONS":
      return {
        iconsNav: {},
        iconsNavQuantity: 0,
        iconsInner: {},
        iconsInnerQuantity: 0,
      };
    default:
      return { ...state };
  }
};

export default iconsReducer;
